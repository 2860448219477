import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || '/api/v1';

export const generateStoreInfo = async (storeName) => {
  const response = await axios.post(`${API_URL}/stores/auto-generate/${encodeURIComponent(storeName)}`);
  return response.data;
};

export const scrapeStoreMenu = async (storeId) => {
  const response = await axios.post(`${API_URL}/products/scrape-menu/${storeId}`);
  return response.data;
};

export const sendChatMessage = async (storeId, chatRequest) => {
  const response = await axios.post(`${API_URL}/chat/${storeId}`, chatRequest, {
    responseType: 'text',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'text/event-stream; charset=utf-8'
    }
  });
  return response.data;
};

export const sendVoiceChatMessage = async (storeId, formData, conversationHistory) => {
  try {
    formData.append('conversation_history', JSON.stringify(conversationHistory));
    const response = await axios.post(`${API_URL}/chat/${storeId}/voice`, formData, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
      }
    });
    // 여기서 헤더를 읽습니다
    const base64RecognizedText = response.headers['x-recognized-text'];
    const recognizedText = base64RecognizedText 
      ? decodeURIComponent(escape(atob(base64RecognizedText))) 
      : '';
    const base64ResponseText  = response.headers['x-response-text'];
    const responseText = base64RecognizedText 
    ? decodeURIComponent(escape(atob(base64ResponseText))) 
    : '';
    console.log(`인코딩된 텍스트: ${base64RecognizedText}`);  // 인코딩된 텍스트 출력
    console.log(`인식된 텍스트: ${recognizedText}`);  // 인식된 텍스트 출력
    console.log(`응답 텍스트: ${responseText}`)
    console.log('All response headers:', response.headers);
    return { data: response.data, recognizedText, responseText };
  } catch (error) {
    if (error.response.status === 400) {
      console.log('인식된 음성이 없습니다.');
      return { error: error.code};
    }

    console.error('Error in sendVoiceChatMessage:', error);
    if (error.response) {
      return { error: error.code || '음성 채팅 중 오류가 발생했습니다.' };
    } else {
      return { error: '네트워크 오류가 발생했습니다.' };
    }
  }
};