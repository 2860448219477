import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../views/Store.vue'
import OnlinePlatform from '../views/OnlinePlatform.vue'
import ChatPage from '../views/ChatPage.vue'
import StoreKiosk from '../views/kiosks/StoreKiosk.vue'
import VoiceChatTest from '@/views/VoiceChatTest.vue'

const routes = [
  {
    path: '/',
    redirect: '/kiosk/1'
  },
  {
    path: '/chat/:storeId',
    name: 'chat',
    component: ChatPage
  },
  {
    path: '/store',
    name: 'Store',
    component: Store
  },
  {
    path: '/online',
    name: 'OnlinePlatform',
    component: OnlinePlatform
  },
  {
    path: '/kiosk/:storeId',
    name: 'StoreKiosk',
    component: StoreKiosk
  },
  {
    path: '/voice-chat-test',
    name: 'VoiceChatTest',
    component: VoiceChatTest
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router